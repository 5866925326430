.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
}

.loader__icons-wrapper {
  position: relative;
  width: 115px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader__circle {
  width: 100%;
  height: 100%;
  border: 2px solid #2563eb;
  border-top: 2px solid rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  -webkit-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

.loader__mini-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 55px;
  height: 55px;
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
