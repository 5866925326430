.WriteReviewStars {
  .star {
    cursor: pointer;

    svg {
      width: 35px;
      height: 35px;
      rect {
        transition: 150ms fill ease-in-out;
      }
    }
  }
}

@media (max-width: 640px) {
  .WriteReviewStars {
    .star {
      svg {
        width: 55px;
        height: 55px;
      }
    }
  }
}
