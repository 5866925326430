.header {
  &__filling-line {
    bottom: -4px;
    height: 4px;
    position: absolute;
    left: 0;
    width: 0%;
    transition: 100ms width ease(fluid);
    background-color: #2563eb;
  }

  nav {
    .menu {
      display: flex;
      align-items: center;

      a {
        display: block;
        margin: 0 15px;
        color: #d1d5db;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  &__dropdown {
    display: none;
  }

  &__hamburger {
    width: 28px;
    height: 20px;
    margin-left: 30px;
    margin-right: 7px;
    cursor: pointer;
    span {
      display: block;
      height: 2px;
      background-color: #fff;
      margin-bottom: 6px;
      border-radius: 15px;
      transition: transform 0.4s cubic-bezier(0.3, 0, 0.22, 1),
        opacity 0.2s cubic-bezier(0.3, 0, 0.22, 1);

      &:first-of-type {
        transform-origin: top right;
      }

      &:last-of-type {
        transform-origin: bottom right;
        margin-bottom: 0;
      }
    }

    &.active {
      span {
        &:first-of-type {
          transform: rotate(-45deg) translateY(-2px);
          top: -1px;
          // margin-bottom: 9px;
        }

        &:nth-of-type(2) {
          transform: translateX(100%);
          opacity: 0;
        }

        &:last-of-type {
          transform: rotate(45deg) translateY(2px);
          bottom: -1px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
    &__dropdown {
      display: block;
      max-height: 0;
      transition: 300ms max-height cubic-bezier(0.3, 0, 0.22, 1);
      background-color: #000;
      position: fixed;
      top: 50px;
      z-index: 50;
      width: 100%;
      overflow: hidden;

      &__content {
        padding: 6px 40px 20px;
      }

      &.active {
        max-height: 750px;
      }

      .menu {
        a {
          display: block;
          margin: 15px 0;
          padding: 10px 0;
          text-align: center;
          color: #d1d5db;

          &:hover {
            color: #fff;
          }
        }
      }

      &__buttons {
        margin-top: 50px;
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
}
